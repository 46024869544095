import React from "react";

const OrderDetailsGood = ({ orderData }) => {
    return (
        <div className="order-good-details">
            <img className="photo-reference" alt="Image" src={`${process.env.REACT_APP_CDN_HOST}/stream/reference/` + orderData.data.photoReference}/>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Товар:</div>
                <div className="order-good-text-wrapper-value">{orderData.data.productName}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Стоимость товара:</div>
                <div className="order-good-text-wrapper-value">¥{orderData.data.cost.unitCost}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Единиц товара:</div>
                <div className="order-good-text-wrapper-value">{orderData.data.cost.amount}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Фрахт:</div>
                <div className="order-good-text-wrapper-value">¥{orderData.data.cost.freight}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">К оплате :</div>
                <div
                    className="order-good-text-wrapper-value">¥{orderData.data.cost.costCNY.toFixed(0)} (₽{orderData.data.cost.costRUB.toFixed(0)})
                </div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Комиссия за выкуп 5%:</div>
                <div
                    className="order-good-text-wrapper-value">¥{orderData.data.cost.buyOutFeeCNY.toFixed(0)} (₽{orderData.data.cost.buyOutFeeRUB.toFixed(0)})
                </div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Срок изготовления:</div>
                <div className="order-good-text-wrapper-value">{orderData.data.productionTime}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Доп. упаковка:</div>
                <div className="order-good-text-wrapper-value">${orderData.data.cost.additionalPackageCostUSD}</div>
            </div>
            <div className="order-good-text">
                <div className="order-good-text-wrapper-name">Страховка 1%:</div>
                <div className="order-good-text-wrapper-value">¥{orderData.data.cost.insuranceCostCNY.toFixed(0)}</div>
            </div>
        </div>
    );
};

export default OrderDetailsGood;